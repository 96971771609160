<template>
  <v-container>
    <v-card-text>
      <p class="account-text-header">Юр. Информация</p>
      <v-text-field
        v-model="company.name"
        outlined
        required
        label="* Юр. лицо"
        :rules="nonEmptyRules"
      >
      </v-text-field>
      <v-text-field
        v-model="company.email"
        outlined
        :rules="emailRules"
        required
        label="* Email"
      >
      </v-text-field>
      <v-select
        v-model="company.country"
        :rules="nonEmptyRules"
        item-text="state"
        item-value="abbr"
        :items="countries"
        outlined
        required
        persistent-hint
        single-line
        prepend-inner-icon="mdi-earth"
        label="* Страна"
      >
      </v-select>
      <v-text-field
        v-model="company.city"
        outlined
        :rules="nonEmptyRules"
        required
        label="* Город"
      >
      </v-text-field>
      <v-text-field
        v-model="company.address"
        outlined
        :rules="nonEmptyRules"
        label="* Адрес"
        required
      >
      </v-text-field>
      <v-text-field
        v-model="company.phone"
        outlined
        :rules="phoneRules"
        required
        label="* Номер телефона"
      >
      </v-text-field>
      <v-text-field
        v-model="company.ogrnip"
        outlined
        label="ОГРНИП"
      />
      <v-text-field
        v-model="company.contract_number"
        outlined
        label="Номер договора"
      />
      <p class="account-text-header">Банковские данные</p>
      <v-text-field
        v-model="company.bank_account"
        outlined
        label="Банковский счет"
      />
      <v-text-field
        v-model="company.bank_name"
        outlined
        label="Банк"
      />
      <v-text-field
        v-model="company.bank_bik"
        outlined
        label="БИК"
      />
      <v-text-field
        v-model="company.bank_inn"
        outlined
        label="ИНН банка"
      />
      <v-btn
        text
        outlined
        color="dark"
        @click="saveAccountCompany"
      >
        Сохранить
      </v-btn>
    </v-card-text>
  </v-container>
</template>

<script>
import account from "@/api/account";

export default {
  name: "CompanyForm",
  data() {
    return {
      company: {
        id: '',
        name: '',
        country: '',
        city: '',
        address: '',
        email: '',
        phone: '',
        contract_number: '',
        contract_date: '',
        inn: '',
        ogrnip: '',
        company_type: '',
        bank_account: '',
        bank_name: '',
        bank_bik: '',
        bank_inn: '',
      },
      selectedCountry: {
        state: 'Россия',
        abbr: 'RU'
      },
      countries: [
        {state: 'Россия', abbr: 'RU'},
        {state: 'Казахстан', abbr: 'KZ'},
        {state: 'Белоруссия', abbr: 'BY'},
      ],
    };
  },
  computed: {
    phoneRules() {
      return [
        v => /^\+\d{11}$/.test(v) || 'Номер телефона нужно писать с +'
      ]
    },
    emailRules() {
      return [
        v => !!v || 'Это поле нужно заполнить',
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(v) || 'Неправильный формат почты'
      ]
    },
    nonEmptyRules() {
      return [
        v => !!v || 'Это поле нужно заполнить'
      ]
    },
  },
  created() {
    this.getCompanyInfo();
  },
  methods: {
    getCompanyInfo: async function () {
      const companyId = this.$route.params.id;
      if (!companyId) {
        return;
      }
      try {
        const response = await account.getCompany(companyId);
        this.company = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    saveAccountCompany: async function () {
      if (!this.validateCompanyInfo()) {
        return;
      }
      if (this.company.id) {
        await this.updateUserCompany();
      } else {
        await this.createUserCompany();
      }
      this.$store.dispatch("alertSuccess", "Информация о компании успешно сохранена");
      return this.$router.push({ name: 'CompanyView', params: { id: this.company.id } });

    },
    validateCompanyInfo: function () {
      if (this.company.name &&
        this.company.country &&
        this.company.city &&
        this.company.address &&
        this.company.email &&
        this.company.phone) {
        return true;
      }
      console.log('Пожалуйста, заполните все обязательные поля');
      this.$store.dispatch("alertError", "Пожалуйста, заполните все обязательные поля");
      return false;
    },
    updateUserCompany: async function () {
      try {
        const response = await account.changeAccountCompanyInfo(this.company);
        console.log('response', response);
        this.company = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    createUserCompany: async function () {
      try {
        const response = await account.createAccountCompanyInfo(this.company);
        this.company = response.data;
        await this.updateUserinfo();
      } catch (e) {
        console.log(e);
      }
    },
  }
}
</script>

<style scoped>

</style>
