<template>
  <v-main>
    <v-card
      class="ma-2"
      flat
    >
      <v-row>
        <v-col cols="12" md="8">
          <v-card-title v-if="companyId">Изменить компанию</v-card-title>
          <v-card-title v-else>Создать компанию</v-card-title>
        </v-col>
      </v-row>
      <CompanyForm />
    </v-card>
  </v-main>
</template>

<script>
import CompanyForm from "@/views/companies/CompanyForm.vue";

export default {
  components: {CompanyForm},
  computed: {
    companyId() {
      return this.$route.params.id;
    },
  },
};
</script>
